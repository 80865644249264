import {
  CartVerificationErrorCode,
  OrderButtonActionType,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import {
  CartsActionTypes,
  updateCartGraphQL,
  verifyCartGraphQL,
  VerifyCartGraphQLResultAction,
  verifyOfferInCartGraphQL,
  VerifyOfferInCartGraphQLResultAction,
} from '../../actions'
import { useActionNotification } from '../useActionNotification'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useDispatch } from 'react-redux'
import { displayOfferInCartNotValid } from '@obeta/utils/lib/displayOrderFailureNotifications'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useTranslation } from 'react-i18next'

export function afterCartVerification(
  selectedCartId: string,
  setCartVerificationErrorCode: (cartVerificationErrorCode: CartVerificationErrorCode) => void,
  setOrderSubmitted: (orderSubmitted: boolean) => void,
  cartVerificationActionType: OrderButtonActionType.Notify | OrderButtonActionType.Submit,
  callback,
  fallbackErrorMessage
) {
  return (action: VerifyCartGraphQLResultAction) => {
    // 3. lack of permissions prevents notify as well as ordersubmit
    if (action.results.length > 0) {
      let didHandleVerifyError = false

      setOrderSubmitted(false)
      const errorCode = action.results[0].errorCode
      switch (errorCode) {
        case CartVerificationErrorCode.UserNotPermitted: {
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.OrderFailure,
            id: `${selectedCartId}-${NotificationType.OrderFailure}`,
            options: {
              reason: OrderFailureReason.NoPermissionGranted,
            },
          })
          setCartVerificationErrorCode(CartVerificationErrorCode.UserNotPermitted)
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.ShippingBlocked: {
          // show notification
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.OrderFailure,
            id: `${selectedCartId}-${NotificationType.OrderFailure}`,
            options: {
              reason: OrderFailureReason.ShippingBlocked,
            },
          })
          setCartVerificationErrorCode(CartVerificationErrorCode.ShippingBlocked)
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.OrderingBlocked: {
          // show notification
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.OrderFailure,
            id: `${selectedCartId}-${NotificationType.OrderFailure}`,
            options: {
              reason: OrderFailureReason.OrderingBlocked,
            },
          })
          setCartVerificationErrorCode(CartVerificationErrorCode.OrderingBlocked)
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.CreditLimitExceeded: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.CreditLimitExceeded)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.ExpressOutOfStock: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ExpressOutOfStock)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.ExpressOrderDeadlineExceeded: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ExpressOrderDeadlineExceeded)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.ContainsProductsNotForSale: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ContainsProductsNotForSale)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.ShippingDateNotPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ShippingDateNotPossible)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.ShippingDateNotPossibleAnymore: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ShippingDateNotPossibleAnymore)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.UserIsCashCustomer: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.UserIsCashCustomer)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.DeliveryOptionNotPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.DeliveryOptionNotPossible)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.PickupOptionNotPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.PickupOptionNotPossible)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.OnlyPickupPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.OnlyPickupPossible)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.WrongPhoneNumber: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.WrongPhoneNumber)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.AddressNotWithinDeliveryArea: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.AddressNotWithinDeliveryArea)
          }
          didHandleVerifyError = true
          break
        }
        case CartVerificationErrorCode.CartWithWarehouseArticlesOutOfStock: {
          // trigger callback since this error should not block the completion of the shopping cart.
          callback()
          didHandleVerifyError = true
          return
        }
        case CartVerificationErrorCode.UserCartMaxValueExceeded: {
          if (cartVerificationActionType === OrderButtonActionType.Notify) {
            // trigger callback since this error should not block the notify function
            callback()
            didHandleVerifyError = true
            return
          }
        }
      }

      // notify is always allowed (only prevented if permissions are not granted)
      if (
        cartVerificationActionType === OrderButtonActionType.Notify &&
        errorCode !== CartVerificationErrorCode.UserNotPermitted
      ) {
        didHandleVerifyError = true
        callback()
      }

      // track if there was an unexpected error
      if (!didHandleVerifyError) {
        const messageIdentifier = 'unhandled-verify-cart-error'
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Error,
          id: messageIdentifier,
          options: {
            message: fallbackErrorMessage,
          },
        })
        trackCustom(messageIdentifier, { errorCode, cartVerificationActionType })
      }

      return
    }
    callback()
  }
}

export function useOnCartUpdateAndVerifySuccess({
  cart,
  setOrderSubmitted,
  cartVerificationActionType,
  setCartVerificationErrorCode,
  setOrderDeclarationsInCart,
  selectedCartId,
  skipVerification,
}: {
  cart?: ShoppingCartV2
  setOrderSubmitted: (orderSubmitted: boolean) => void
  cartVerificationActionType: OrderButtonActionType
  setCartVerificationErrorCode: (cartVerificationErrorCode: CartVerificationErrorCode) => void
  setOrderDeclarationsInCart: () => void
  selectedCartId: string
  skipVerification?: boolean
}) {
  const dispatch = useDispatch()
  const waitForResultAction = useActionNotification(CartsActionTypes.VerifyCartGraphQLResult)
  const waitForUpdateCartResultAction = useActionNotification(
    CartsActionTypes.UpdateCartGraphQLResult
  )
  const waitForVerifyOfferInCartResultAction = useActionNotification(
    CartsActionTypes.VerifyOfferInCartGraphQLResult
  )
  const { t } = useTranslation()

  if (!cart) return

  return (callback, preventCartUpdate?: boolean) => {
    if (cartVerificationActionType === OrderButtonActionType.Submit && setOrderSubmitted) {
      setOrderSubmitted(true)
    }

    // cart update on dedicated create-order page is done in a previous step
    if (!preventCartUpdate) {
      setOrderDeclarationsInCart()

      // 1. save
      dispatch(updateCartGraphQL(cart))
    }

    // 2a. - no verification needed? -> submit or notify immediately
    if (skipVerification) {
      callback()
      return
    }

    // 2b. verify
    waitForUpdateCartResultAction(() => {
      dispatch(verifyCartGraphQL(selectedCartId))
    })
    waitForResultAction((resultAction) => {
      const callAfterCartVerification = afterCartVerification(
        selectedCartId,
        setCartVerificationErrorCode,
        setOrderSubmitted,
        cartVerificationActionType,
        callback,
        t('SHOPPING_CART.UNKNOWN_ERROR')
      )

      if (cart.offerId !== '') {
        // 3. verify offer in cart
        dispatch(verifyOfferInCartGraphQL(selectedCartId))
        waitForVerifyOfferInCartResultAction((action: VerifyOfferInCartGraphQLResultAction) => {
          if (!action.success) {
            displayOfferInCartNotValid(selectedCartId, action.result)
          } else {
            callAfterCartVerification(resultAction)
          }
        })
      } else {
        callAfterCartVerification(resultAction)
      }
    })
  }
}
