import React, { useState } from 'react'
import clsx from 'clsx'
import { GuardedImageTile } from '@obeta/components/lib/tiles'
import profilePicture from 'assets/img/Customer_Account/customerCardAvatar.svg'
import { IconButton, Typography } from '@mui/material'
import { ImagePickerPopover } from '@obeta/components/lib/image-picker-popover/ImagePickerPopover'
import { ReactComponent as EditIcon } from 'assets/icon/designsystem/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { Modal } from '@obeta/components/lib/modal/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import { Alert } from '@obeta/components/lib/alert-and-confirm/Alert'
import { useAvatarUpload } from '../../../../../hooks/useAvatarUpload'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useImgProxyUrls } from '@obeta/data/lib/hooks/useImgProxyUrls'
import { belowMdQuery, useMediaQuery } from '@obeta/data/lib/hooks'
import { useTranslation } from 'react-i18next'
import { getCustomerCardId } from '../info-section/InfoSection'
import styles from './LogoSection.module.scss'

/*Reenable when deletion is solved https://coditorei.atlassian.net/browse/COD-19022 */
const IS_REMOVE_BTN_API_READY = false

export const LogoSection = () => {
  const { t } = useTranslation()
  const [isAvatarSelectMenuVisible, setAvatarSelectMenuVisible] = useState(false)
  const { isUploading, changeCustomerImage, uploadError, setUploadError } = useAvatarUpload({
    setAvatarSelectMenuVisible,
  })
  const isBelowMd = useMediaQuery(belowMdQuery)

  const { user: activeUser } = useUserDataV2()

  const customerCardActivatedId = getCustomerCardId(activeUser)

  const isAvatarPresent = activeUser?.settings?.profilePictureS3Path

  const { imgProxyUrlMobile, imgProxyUrlTablet } = useImgProxyUrls(isAvatarPresent || '')

  const deleteUserPhotoHandler = () => {
    // TODO: Api is not ready yet
  }

  const getAvatarUrl = () => {
    if (isAvatarPresent) return isBelowMd ? imgProxyUrlMobile : imgProxyUrlTablet
    return ''
  }

  return (
    <>
      <div className={styles.logoSection}>
        <div className={styles.imageOuter}>
          <div
            className={styles.image}
            onClick={!isAvatarPresent ? () => setAvatarSelectMenuVisible(true) : undefined}
          >
            <GuardedImageTile
              className={clsx(styles.imageContent, { [styles.dummyImage]: !isAvatarPresent })}
              imageUrl={isAvatarPresent ? getAvatarUrl() : profilePicture}
            />
          </div>
          {customerCardActivatedId && <div className={styles.noScreenshotIndicator}></div>}
          {isAvatarPresent && (
            <>
              <IconButton
                className={styles.editUserPhotoButton}
                onClick={() => setAvatarSelectMenuVisible(true)}
              >
                <EditIcon />
              </IconButton>
              {IS_REMOVE_BTN_API_READY && (
                <IconButton
                  className={styles.deleteUserPhotoButton}
                  onClick={deleteUserPhotoHandler}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          )}
        </div>
      </div>
      <ImagePickerPopover
        isOpen={isAvatarSelectMenuVisible}
        onClose={() => setAvatarSelectMenuVisible(false)}
        takePictureCallback={changeCustomerImage}
      />
      <Modal fitContent open={isUploading}>
        <div className={styles.loadingContent}>
          <CircularProgress className={styles.progressSpin} />
          <Typography variant="bodyBold">{t('CUSTOMER_PROFILE.IMAGE_UPLOAD_LOADING')}</Typography>
        </div>
      </Modal>
      <Alert
        heading={uploadError || ''}
        handleConfirm={() => setUploadError('')}
        openAlertDialog={!!uploadError}
      />
    </>
  )
}
