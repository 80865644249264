import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'
import { emailExpression } from '@obeta/utils/lib/isValidEmail'
import { PHONE_PATTERN } from '@obeta/utils/lib/isValidPhone'
import { WEBSITE_PATTERN } from '@obeta/utils/lib/isValidWebsite'
import { FormField } from './FormField'

const placeholders = {
  website: 'http://www.firmenwebsite.de',
  email: 'kontakt@firma.de',
  phone: '+49 30 12345678',
}

export const ContactSection = () => {
  const { t } = useTranslation()

  const websiteRules = {
    required: t('SETTINGS.FIRM_DATA_SECTION.ERRORS.WEBSITE_INVALID'),
    pattern: {
      value: WEBSITE_PATTERN,
      message: t('SETTINGS.FIRM_DATA_SECTION.ERRORS.WEBSITE_INVALID'),
    },
  }

  const phoneRules = {
    required: t('SHOPPING_CART.CHECKOUT.ERROR.PHONE_INVALID'),
    pattern: {
      value: PHONE_PATTERN,
      message: t('SHOPPING_CART.CHECKOUT.ERROR.PHONE_INVALID'),
    },
  }

  const emailRules = {
    required: t('OFFERINQUIRY.ERROR.EMAIL_REQUIRED'),
    pattern: {
      value: emailExpression,
      message: t('OFFERINQUIRY.ERROR.EMAIL_INVALID'),
    },
  }

  return (
    <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 1.5 }}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField
          name="website"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.WEBSITE')}
          placeholder={placeholders.website}
          rules={websiteRules}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField
          name="mail"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.EMAIL')}
          placeholder={placeholders.email}
          rules={emailRules}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField
          name="phone"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.PHONE')}
          placeholder={placeholders.phone}
          rules={phoneRules}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormField
          name="fax"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.FAX')}
          placeholder={placeholders.phone}
          rules={phoneRules}
        />
      </Grid>
    </Grid>
  )
}
