import React, { useState } from 'react'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import { Typography, TextField, Box, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ArrowLeftIcon } from 'assets/icon/designsystem/keyboard-arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icon/designsystem/keyboard-arrow-right.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { useTranslation } from 'react-i18next'
import { TabContext, TabPanel } from '@mui/lab'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import { Counter } from '../../counter/Counter'
import { Dropdown2 } from '../../dropdown2/Dropdown2'
import { DarkButton, TertiaryButton } from '../../custom-button/CustomButton'
import styles from './SignUpStepTwo.module.scss'

const SignUpStepTwo = ({
  control,
  errors,
  handleSubmit,
  onSubmit,
  setValue,
  getValues,
  Options,
  amount,
  handleAmountChange,
  value,
}) => {
  const { t } = useTranslation()
  const { mobile, tablet } = useBreakpoints()
  const [initialAmount] = useState<number | null>(0)
  const [minimumAmount] = useState<number>(1)
  const theme = useTheme()
  const formValues = getValues()

  const requiredFieldsFilled = [
    'companyName',
    'street',
    'houseNumber',
    'zipCode',
    'city',
    'taxNumber',
    'invoiceEmail',
  ].every((field) => formValues[field])

  const isElectricalCompanyOrElectricianProvided =
    formValues.companyType === 'electrical' || formValues.electrician

  const isCompanyTypeProvided = formValues.companyType !== 'other' || formValues.companyDescription

  const isFormValid = (): boolean => {
    return requiredFieldsFilled && isElectricalCompanyOrElectricianProvided && isCompanyTypeProvided
  }

  return (
    <div className={clsx([styles.center, mobile && styles.centerMobile])}>
      <TabContext value={value}>
        <TabPanel className={styles.noPadding} value="2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={clsx([
                styles.formContainer,
                tablet && styles.formContainerTabletWide,
                mobile && styles.mobile,
              ])}
            >
              <div className={clsx([styles.TextField, mobile && styles.TextFieldMobile])}>
                <Typography variant="body">{t('COMMON.SIGNUP.COMPANYNAME')}</Typography>

                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={!!errors.companyName}
                      variant="outlined"
                      id="companyName"
                      name="companyName"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      helperText={
                        errors.companyName ? (
                          <Box display="flex">
                            <SvgIcon
                              className={styles.helperTextIcon}
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.companyName.type === 'maxLength'
                                ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                                : ''}
                              {errors.companyName.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.COMPANYNAME')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{
                        formHelperText: {
                          className: styles.helperTextContainer,
                        },
                      }}
                    />
                  )}
                  name="companyName"
                  defaultValue={''}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 35,
                  }}
                />
              </div>

              <Box>
                <Typography variant="body">{t('COMMON.SIGNUP.CUSTOMER_COUNT')}</Typography>

                <Controller
                  name="customerCardCount"
                  control={control}
                  defaultValue={0}
                  rules={{ required: true }}
                  render={() => (
                    <Counter
                      variant="big"
                      initialAmount={initialAmount}
                      amount={amount ?? 0}
                      minimumAmount={minimumAmount}
                      isZeroAccepted={true}
                      changeProductAmount={handleAmountChange}
                      stretchHorizontal={true}
                    />
                  )}
                />
              </Box>
            </div>

            <div
              className={clsx([
                styles.formContainer,
                tablet && styles.formContainerTabletWide,
                mobile && styles.mobile,
              ])}
            >
              <div className={clsx([styles.TextField, mobile && styles.TextFieldMobileSmall])}>
                <Typography variant="body">{t('COMMON.SIGNUP.STREET')}</Typography>

                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={!!errors.street}
                      variant="outlined"
                      id="street"
                      name="street"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      helperText={
                        errors.street ? (
                          <Box display="flex">
                            <SvgIcon
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                              className={styles.helperTextIcon}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.street.type === 'maxLength'
                                ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                                : ''}
                              {errors.street.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.STREET')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{ formHelperText: { className: styles.helperTextContainer } }}
                    />
                  )}
                  name="street"
                  defaultValue={''}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 35,
                  }}
                />
              </div>

              <div className={clsx([styles.TextField, mobile && styles.TextFieldMobileSmall])}>
                <Typography variant="body">{t('COMMON.SIGNUP.HOUSENUMBER')}</Typography>

                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="houseNumber"
                      name="houseNumber"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!errors.houseNumber}
                      helperText={
                        errors.houseNumber ? (
                          <Box display="flex">
                            <SvgIcon
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                              className={styles.helperTextIcon}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.houseNumber.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.HOUSENUMBER')
                                : ''}
                              {errors.houseNumber.type === 'pattern'
                                ? t('COMMON.SIGNUP.ERROR.HOUSENUMBER')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{
                        formHelperText: {
                          className: styles.helperTextContainer,
                        },
                      }}
                    />
                  )}
                  name="houseNumber"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[0-9a-zA-Z\s\-/]{1,5}$/,
                      message: '',
                    },
                  }}
                />
              </div>

              <div className={clsx([styles.TextField, mobile && styles.TextFieldMobileSmall2])}>
                <Typography variant="body">{t('COMMON.SIGNUP.POSTALCODE')}</Typography>

                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="zipCode"
                      name="zipCode"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!errors.zipCode}
                      helperText={
                        errors.zipCode ? (
                          <Box display="flex">
                            <SvgIcon
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                              className={styles.helperTextIcon}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.zipCode.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.POSTALCODE')
                                : ''}
                              {errors.zipCode.type === 'pattern'
                                ? t('COMMON.SIGNUP.ERROR.POSTALCODE')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{
                        formHelperText: {
                          className: styles.helperTextContainer,
                        },
                      }}
                    />
                  )}
                  name="zipCode"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[0-9\s\-/]{1,5}$/,
                      message: '',
                    },
                  }}
                />
              </div>

              <div className={clsx([styles.TextField, mobile && styles.TextFieldMobileSmall2])}>
                <Typography variant="body">{t('COMMON.SIGNUP.PLACE')}</Typography>

                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={!!errors.city}
                      variant="outlined"
                      id="city"
                      name="city"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      helperText={
                        errors.city ? (
                          <Box display="flex">
                            <SvgIcon
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                              className={styles.helperTextIcon}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.city.type === 'maxLength'
                                ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                                : ''}
                              {errors.city.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.PLACE')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{
                        formHelperText: {
                          className: styles.helperTextContainer,
                        },
                      }}
                    />
                  )}
                  name="city"
                  defaultValue={''}
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 35,
                  }}
                />
              </div>
            </div>

            <div
              className={clsx([
                !mobile && !tablet && styles.formContainer,
                tablet && styles.formContainerTabletWide,
                mobile && styles.mobile,
              ])}
            >
              <div className={clsx([styles.TextField, mobile && styles.TextFieldMobile])}>
                <Typography variant="body">{t('COMMON.SIGNUP.TAXNUMBER')}</Typography>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="taxNumber"
                      name="taxNumber"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!errors.taxNumber}
                      helperText={
                        errors.taxNumber ? (
                          <Box display="flex">
                            <SvgIcon
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                              className={styles.helperTextIcon}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.taxNumber.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.TAXNUMBER')
                                : ''}
                              {errors.taxNumber.type === 'pattern'
                                ? t('COMMON.SIGNUP.ERROR.TAXNUMBER')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{
                        formHelperText: {
                          className: styles.helperTextContainer,
                        },
                      }}
                    />
                  )}
                  name="taxNumber"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^DE[0-9]{9}$/,
                      message: '',
                    },
                  }}
                />
              </div>
              <div
                className={clsx([
                  styles.TextField,
                  mobile && styles.TextFieldMobile && styles.itemRow,
                ])}
              >
                <Typography variant="body">{t('COMMON.SIGNUP.INVOICE_E-MAIL')}</Typography>

                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="invoiceEmail"
                      name="invoiceEmail"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.invoiceEmail}
                      value={value}
                      helperText={
                        errors.invoiceEmail ? (
                          <Box display="flex">
                            <SvgIcon
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                              className={styles.helperTextIcon}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.invoiceEmail.type === 'required'
                                ? t('COMMON.SIGNUP.ERROR.INVOICE_E-MAIL')
                                : ''}
                              {errors.invoiceEmail.type === 'pattern'
                                ? t('COMMON.SIGNUP.ERROR.INVOICE_E-MAIL')
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                      slotProps={{
                        formHelperText: {
                          className: styles.helperTextContainer,
                        },
                      }}
                    />
                  )}
                  name="invoiceEmail"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: '',
                    },
                  }}
                />
              </div>
            </div>
            <div
              className={clsx([
                !mobile && !tablet && styles.formContainer,
                tablet && styles.formContainerTabletWide,
                mobile && styles.mobile,
              ])}
            >
              <Controller
                name="companyType"
                control={control}
                defaultValue={'electrical'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <div
                      className={clsx([
                        !mobile && !tablet && styles.formContainer,
                        tablet && styles.formContainerTabletWide,
                        mobile && styles.mobile,
                      ])}
                    >
                      <div className={clsx([styles.TextField, mobile && styles.TextFieldMobile])}>
                        <Typography variant="body">{t('COMMON.SIGNUP.TYPE_OF_COMPANY')}</Typography>
                        <Dropdown2
                          width={mobile || tablet ? '21.5rem' : '22rem'}
                          options={Options}
                          value={value || 'electrical'}
                          onChange={onChange}
                        />
                      </div>

                      {value === 'other' && (
                        <div className={clsx([styles.TextField, mobile && styles.TextFieldMobile])}>
                          <Typography variant="body">
                            {t('COMMON.SIGNUP.TYPE_OF_COMPANY')}
                          </Typography>

                          <Controller
                            name="companyDescription"
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="companyDescription"
                                name="companyDescription"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={!!errors.companyDescription}
                                helperText={
                                  errors.companyDescription ? (
                                    <Box display="flex">
                                      <SvgIcon
                                        component={WarningIcon}
                                        fontSize="small"
                                        htmlColor={theme.palette.primary.main}
                                        className={styles.helperTextIcon}
                                      />
                                      <Typography
                                        variant={'smallText'}
                                        color={theme.palette.primary.main}
                                      >
                                        {errors.companyDescription.type === 'required'
                                          ? t('COMMON.SIGNUP.ERROR.TYPE_OF_COMPANY')
                                          : errors.companyDescription.type === 'maxLength'
                                          ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                                          : ''}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    ''
                                  )
                                }
                                slotProps={{
                                  formHelperText: {
                                    className: styles.helperTextContainer,
                                  },
                                }}
                              />
                            )}
                            rules={{ required: value === 'other' }}
                          />
                        </div>
                      )}
                    </div>
                    {value !== 'electrical' && (
                      <>
                        {value !== 'other' && <Typography> </Typography>}{' '}
                        <div className={clsx([styles.TextField, mobile && styles.TextFieldMobile])}>
                          <Typography
                            variant="body"
                            style={{
                              width: tablet ? '44.5rem' : !mobile ? '46rem' : '22rem',
                            }}
                          >
                            {t('COMMON.SIGNUP.AOB')}
                          </Typography>
                          <Controller
                            name="electrician"
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="electrician"
                                name="electrician"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={!!errors.electrician}
                                helperText={
                                  errors.electrician ? (
                                    <Box display="flex">
                                      <SvgIcon
                                        component={WarningIcon}
                                        fontSize="small"
                                        htmlColor={theme.palette.primary.main}
                                        className={styles.helperTextIcon}
                                      />
                                      <Typography
                                        variant={'smallText'}
                                        color={theme.palette.primary.main}
                                      >
                                        {errors.electrician.type === 'required'
                                          ? t('COMMON.SIGNUP.ERROR.AOB')
                                          : errors.electrician.type === 'maxLength'
                                          ? t('COMMON.SIGNUP.ERROR.MAX_LENGTH')
                                          : ''}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    ''
                                  )
                                }
                                slotProps={{
                                  formHelperText: {
                                    className: styles.helperTextContainer,
                                  },
                                }}
                                style={{
                                  width: tablet ? '44.5rem' : !mobile ? '46rem' : '22rem',
                                }}
                              />
                            )}
                            rules={{ required: value !== 'electrical' }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              />
            </div>
            <div className={clsx([styles.formContainer, mobile && styles.formContainerMobile])}>
              <div className={styles.left}>
                <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={() => setValue('1')}>
                  {t('COMMON.SIGNUP.BACK')}
                </TertiaryButton>
              </div>
              <div className={styles.right}>
                <Typography className={styles.size}>{t('COMMON.SIGNUP.REQUIRED_FIELD')}</Typography>
                <div className={styles.button}>
                  <DarkButton
                    rightIcon={<ArrowRightIcon />}
                    disabled={!isFormValid()}
                    fullWidth
                    onClick={() => setValue('3')}
                  >
                    {t('COMMON.SIGNUP.NEXT')}
                  </DarkButton>
                </div>
              </div>
            </div>
          </form>
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default SignUpStepTwo
