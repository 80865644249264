import React from 'react'
import { Checkbox } from '../checkbox/Checkbox'
import { Controller } from 'react-hook-form'
import { FileUpload } from '../file-upload/FileUpload'
import { ReactComponent as AttachFileIcon } from 'assets/icon/designsystem/attach-file.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ShopLink } from '../link/ShopLink'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import styles from './SignUpStepThree.module.scss'
import { SvgIcon, TextField, Typography } from '@mui/material'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import { useSignUpStepThree } from '@obeta/data/lib/hooks/useUserRegistration'
import { useTranslation } from 'react-i18next'

const SignUpStepThreeForm = ({ control }) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const {
    termsAccepted,
    setTermsAccepted,
    privacyPolicyAccepted,
    setPrivacyPolicyAccepted,
    fileError,
    uploadedFiles,
    isFileUploading,
    handleDropAccepted,
    handleDropRejected,
    handleDeleteFile,
    MAX_FILE_COUNT,
    MAX_FILE_SIZE_IN_BYTES,
  } = useSignUpStepThree()
  return (
    <>
      <div className={styles.checkboxWrapper}>
        <div className={styles.checkbox}>
          <Checkbox
            checked={termsAccepted}
            onChange={(event) => setTermsAccepted(event.target.checked)}
          />
          <div>
            <Typography variant="body">
              {t('SIGNUP.TERMS_OF_SERVICE_ACCEPTED.0')}
              <ShopLink
                href={ShopRoutes.Terms}
                aria-label={t('SIGNUP.TERMS_OF_SERVICE_ACCEPTED.1')}
                target="_blank"
                className={styles.blackLink}
              >
                {t('SIGNUP.TERMS_OF_SERVICE_ACCEPTED.1')}
              </ShopLink>
            </Typography>
            <Typography variant="body">{`${t('SIGNUP.TERMS_OF_SERVICE_ACCEPTED.2')} *`}</Typography>
          </div>
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            checked={privacyPolicyAccepted}
            onChange={(event) => setPrivacyPolicyAccepted(event.target.checked)}
          />
          <div>
            <Typography variant={'body'}>
              {t('SIGNUP.PRIVACY_POLICY_ACCEPTED.0')}
              <ShopLink
                href={ShopRoutes.PrivacyProtection}
                aria-label={t('SIGNUP.PRIVACY_POLICY_ACCEPTED.1')}
                target="_blank"
                className={styles.blackLink}
              >
                {t('SIGNUP.PRIVACY_POLICY_ACCEPTED.1')}
              </ShopLink>
            </Typography>
            <Typography variant={'body'}>{`${t('SIGNUP.PRIVACY_POLICY_ACCEPTED.2')} *`}</Typography>
          </div>
        </div>{' '}
      </div>
      <div className={styles.uploadWrapper}>
        <Typography variant="body">{t('SIGNUP.UPLOAD_BLURB')}</Typography>
        <FileUpload
          accept={{
            'application/pdf': ['.pdf'],
            'image/jpeg': ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif'],
            'image/png': ['.png'],
          }}
          disabled={isFileUploading}
          error={fileError}
          files={uploadedFiles}
          hideAcceptedFiles={true}
          onDeleteFile={handleDeleteFile}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
          maxFiles={MAX_FILE_COUNT}
          maxSize={MAX_FILE_SIZE_IN_BYTES}
        />
        {/* Display list of successfully uploaded files */}
        {uploadedFiles.length > 0 &&
          uploadedFiles.map((uploadedFile, index) => (
            <div key={index} className={styles.uploadedFileListing}>
              <div className={styles.iconAndTitleWrapper}>
                <SvgIcon component={AttachFileIcon} />
                <Typography variant={'bodyBold'}>{uploadedFile.name ?? ''}</Typography>
              </div>
              <TertiaryIconButton
                icon={<CloseIcon />}
                onClick={() => handleDeleteFile(uploadedFile.s3Key)}
                size={desktop ? 'small' : 'large'}
              />
            </div>
          ))}
      </div>
      {/* Additional text field */}
      <div className={styles.additionalText}>
        <Typography variant="body">{t('SIGNUP.ADDITIONAL_TEXT')}</Typography>
        <Controller
          name="additionalText"
          control={control}
          rules={{ maxLength: 130 }}
          render={({ field }) => (
            <TextField
              {...field}
              className={styles.additionalTextInput}
              variant="outlined"
              multiline
              placeholder={t('SIGNUP.YOUR_ADDITIONAL_TEXT')}
              slotProps={{ htmlInput: { maxLength: 130 } }}
              rows={4}
            />
          )}
        />
      </div>
    </>
  )
}

export default SignUpStepThreeForm
