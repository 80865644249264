import React, { FC } from 'react'
import styles from './shopAccessActivationPage.module.scss'
import { Box, SvgIcon, TextField, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import { DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as ArrowRightIcon } from '@obeta/assets/icon/designsystem/keyboard-arrow-right.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { trackClick } from '@obeta/utils/lib/tracking'
import { useTranslation } from 'react-i18next'

export const ShopAccessActivationPage: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const theme = useTheme()
  const red100pct = theme.palette.primary.main

  const {
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      applicant: '',
      companyName: '',
      customerNumber: '',
      emailAddress: '',
      phoneNumber: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const SEND_MAIL_API_ROUTE = '/api/sendmail'

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // Get form data
    const values = getValues()
    const { customerNumber } = values

    const emailData = {
      // to: 'pin@obeta.de',
      to: 'm.daluege@obeta.de',
      subject: t('SHOP_ACCESS.EMAIL.SUBJECT', { customerNumber: customerNumber }),
      text: t('SHOP_ACCESS.EMAIL.BODY', {
        applicant: values.applicant,
        companyName: values.companyName,
        customerNumber: customerNumber,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
      }),
      host: process.env.NEXT_PUBLIC_MAILGUN_HOST,
      port: process.env.NEXT_PUBLIC_MAILGUN_PORT,
    }

    try {
      await fetch(SEND_MAIL_API_ROUTE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      })

      // Log action in Datadog
      trackClick('shop-access-activation-form-submitted', {
        customerNumber: customerNumber,
      })
      // Redirect to start page
      history.push(ShopRoutes.Root)
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  const renderFormField = (
    name: 'applicant' | 'companyName' | 'customerNumber' | 'emailAddress' | 'phoneNumber',
    label: string,
    rules: object
  ) => (
    <div className={styles.field}>
      <Typography variant="body">{t(`SHOP_ACCESS.${label}`)}</Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            error={!!errors[name]}
            variant="outlined"
            helperText={
              errors[name] && (
                <Box display="flex">
                  <SvgIcon
                    className={styles.helperTextIcon}
                    component={WarningIcon}
                    fontSize="small"
                    htmlColor={red100pct}
                  />
                  <Typography variant="smallText" color={red100pct}>
                    {t(`SHOP_ACCESS.ERROR.${label}`)}
                  </Typography>
                </Box>
              )
            }
            slotProps={{
              formHelperText: {
                className: styles.helperTextContainer,
              },
            }}
          />
        )}
      />
    </div>
  )

  return (
    <div className={styles.container}>
      <Typography variant={'headline3Bold'} className={styles.headline}>
        {t('SHOP_ACCESS.SHOP_ACCESS_ACTIVATION')}
      </Typography>
      <Typography variant={'body'}>{t('SHOP_ACCESS.BENEFITS_SHOP_ACCESS')}</Typography>
      <form className={clsx(styles.form)} onSubmit={handleFormSubmit}>
        {renderFormField('applicant', 'APPLICANT', { required: true })}
        {renderFormField('companyName', 'COMPANY_NAME', { required: true })}
        {renderFormField('customerNumber', 'CUSTOMER_NUMBER', {
          required: true,
          pattern: /^[0-9]+$/,
        })}
        {renderFormField('emailAddress', 'EMAIL_ADDRESS', {
          required: true,
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        })}
        {renderFormField('phoneNumber', 'PHONE_NUMBER', {
          required: true,
          minLength: 6,
          maxLength: 20,
          pattern: /^[0-9+-]+$/,
        })}
        <div className={styles.requiredText}>
          <Typography variant="body">{`* ${t('SHOP_ACCESS.REQUIRED_FIELD')}`}</Typography>
        </div>
        <div className={styles.submitButtonContainer}>
          <DarkButton
            classes={{ root: styles.submitButton }}
            type="submit"
            rightIcon={<ArrowRightIcon />}
            disabled={!isValid}
          >
            {t('SHOP_ACCESS.APPLY_NOW')}
          </DarkButton>
        </div>
      </form>
    </div>
  )
}

export default ShopAccessActivationPage
