import React, { FC, useState } from 'react'
import { UserSubuser } from '@obeta/models/lib/schema-models/userV2-subusers'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as AdminPanelSettingsIcon } from 'assets/icon/designsystem/admin_panel_settings.svg'
import { ReactComponent as RuleFolderIcon } from 'assets/icon/designsystem/rule_folder.svg'
import { ReactComponent as PaymentsIcon } from 'assets/icon/designsystem/payments.svg'
import { ReactComponent as LocalShippingIcon } from 'assets/icon/designsystem/local_shipping.svg'
import {
  getPermissionsStatus,
  PERMISSION_STATUS,
  PERMISSION_STATUS_ALL,
  PERMISSION_STATUS_CUSTOM,
  PERMISSION_STATUS_NONE,
  PERMISSION_STATUS_COPIED,
} from '@obeta/utils/lib/permissions'
import { CollapseComponent } from '../../searchPage/filters/CollapseComponent'
import { CollapsibleCheckboxGroup } from '../form/CollapsibleCheckboxGroup'
import styles from './UserPermission.module.scss'
import { useUserOverviewTableData } from '@obeta/data/lib/hooks/user-overview/useUserOverviewTableData'
import formStyles from '../form/form.module.scss'
import { useUserManagementReadOnly } from '../form/useUserManagementReadOnly'
import { Select } from '@obeta/components/lib/select/Select'
import stylesMyProfile from '../MyProfilePage.module.scss'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

type UserPermissionFormProps = {
  user: UserSubuser
  updateUser: (value: UserSubuser) => void
  setAllPermissions: (value: PERMISSION_STATUS) => void
  forceMinWidth?: boolean
  forceColumnLayout?: boolean
  compact?: boolean
}

export type UserPermissionElement = {
  translationKey: string
  tooltipTranslationKey: string
}

const PERMISSION_KEYS_GROUP_ONE = [
  {
    translationKey: 'Address_canWrite',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Address_canWrite',
  },
  {
    translationKey: 'Global_canSeeUserManagement',
    tooltipTranslationKey:
      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_canSeeUserManagement',
  },
  {
    translationKey: 'Projects_canManage',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Projects_canManage',
  },
]
const PERMISSION_KEYS_GROUP_TWO = [
  {
    translationKey: 'Global_canReadAccountInfo',
    tooltipTranslationKey:
      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_canReadAccountInfo',
  },
  {
    translationKey: 'Global_showCreditWarning',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_showCreditWarning',
  },
  {
    translationKey: 'Global_canReadPrices',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_canReadPrices',
  },
]
const PERMISSION_KEYS_GROUP_THREE = [
  {
    translationKey: 'Global_canUseCustomDataSheet',
    tooltipTranslationKey:
      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_canUseCustomDataSheet',
  },
  {
    translationKey: 'Global_canChangeDataSheetPrice',
    tooltipTranslationKey:
      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_canChangeDataSheetPrice',
  },
  {
    translationKey: 'Orders_canRead',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Orders_canRead',
  },
  {
    translationKey: 'Offers_canRead',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Offers_canRead',
  },
  {
    translationKey: 'User_shouldReceiveOrderConfirmations',
    tooltipTranslationKey:
      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.User_shouldReceiveOrderConfirmations',
  },
  {
    translationKey: 'Global_canUseInterfaces',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Global_canUseInterfaces',
  },
]
const PERMISSION_KEYS_GROUP_FOUR = [
  {
    translationKey: 'Cart_canChangePayment',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Cart_canChangePayment',
  },
  {
    translationKey: 'Cart_canWriteCommission',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Cart_canWriteCommission',
  },
  {
    translationKey: 'Cart_canWriteAddress',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Cart_canWriteAddress',
  },
  {
    translationKey: 'Cart_requiredCommission',
    tooltipTranslationKey: 'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Cart_requiredCommission',
  },
  {
    translationKey: 'Cart_canWriteAdditionalText',
    tooltipTranslationKey:
      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.TOOLTIPS.Cart_canWriteAdditionalText',
  },
]

export const UserPermission: FC<UserPermissionFormProps> = (props) => {
  const { t } = useTranslation()
  const { user, updateUser, setAllPermissions, forceMinWidth, forceColumnLayout, compact } = props
  const { desktop, mobile, tablet } = useBreakpoints()
  const [forcedCustomStatus, setForcedCustomStatus] = useState(false)
  const [copiedCustomStatus, setCopiedCustomStatus] = useState(false)
  const { sortedItems: users } = useUserOverviewTableData()
  const isReadOnlyUser = useUserManagementReadOnly()
  const activeUser = useUserDataV2()

  const handleChangeParentCheckbox = (
    permissions: UserPermissionElement[],
    parentChecked: boolean
  ) => {
    setForcedCustomStatus(false)
    for (const permission of permissions) {
      if (permission.translationKey === 'Global_canReadPrices') {
        user.permissions[permission.translationKey] = parentChecked ? 'PurchasePrice' : 'NoPrice'
      } else {
        user.permissions[permission.translationKey] = parentChecked
      }
    }
    updateUser({ ...user })
  }

  const initialIndeterminateSet = (permissions: UserPermissionElement[]): boolean => {
    if (!user) return false
    let setIndeterminate = false
    let minimumOnePermissionSet = false
    for (const permission of permissions) {
      if (
        !user.permissions[permission.translationKey] ||
        (permission.translationKey === 'Global_canReadPrices' &&
          user.permissions[permission.translationKey] === 'NoPrice') ||
        user.permissions[permission.translationKey] === ''
      )
        setIndeterminate = true
      if (
        (permission.translationKey !== 'Global_canReadPrices' &&
          user.permissions[permission.translationKey]) ||
        (permission.translationKey === 'Global_canReadPrices' &&
          user.permissions[permission.translationKey] !== 'NoPrice' &&
          user.permissions[permission.translationKey] !== '')
      )
        minimumOnePermissionSet = true
    }
    return setIndeterminate && minimumOnePermissionSet
  }

  const copyUserPermissions = (customerNumber: string) => {
    const selectedUserPermissions = users.find(
      (user) => user.customerNumber === customerNumber
    )?.permissions
    if (selectedUserPermissions) {
      user.permissions = selectedUserPermissions
      updateUser({ ...user })
    }
  }

  const hasForcedColumnLayout = !mobile && forceColumnLayout
  const hasColumnLayout = hasForcedColumnLayout || tablet
  return (
    <div
      className={clsx(
        !activeUser?.permissions?.Global_canSeeUserManagement && stylesMyProfile.disabled
      )}
    >
      <Box className={styles.basicStyling}>
        <RadioGroup
          row={!mobile}
          aria-labelledby="radio-buttons-group-grant-permissions"
          defaultValue={PERMISSION_STATUS_ALL}
          name="radio-buttons-group"
          className={clsx(
            styles.paddingOneRem,
            styles.radioGroupStyles,
            styles.groupPermissions,
            hasColumnLayout && styles.tablet
          )}
          value={getPermissionsStatus(user.permissions, forcedCustomStatus, copiedCustomStatus)}
          onChange={(event, value) => {
            setForcedCustomStatus(value === PERMISSION_STATUS_CUSTOM)
            setCopiedCustomStatus(value === PERMISSION_STATUS_COPIED)
            setAllPermissions(value as PERMISSION_STATUS)
          }}
        >
          <FormControlLabel
            style={{ order: 1 }}
            className={styles.noMargin}
            value={PERMISSION_STATUS_ALL}
            control={
              <Radio
                disabled={isReadOnlyUser}
                className={clsx(!desktop && formStyles.radioButton, desktop && styles.radioButton)}
              />
            }
            label={
              <Typography variant="bodyBold">
                {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.GRANT_ALL_PERMISSIONS')}
              </Typography>
            }
          />
          <FormControlLabel
            style={{ order: 2 }}
            className={styles.noMargin}
            value={PERMISSION_STATUS_NONE}
            control={
              <Radio
                disabled={isReadOnlyUser}
                className={clsx(!desktop && formStyles.radioButton, desktop && styles.radioButton)}
              />
            }
            label={
              <Typography variant="bodyBold">
                {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.GRANT_NO_PERMISSIONS')}{' '}
              </Typography>
            }
          />
          <FormControlLabel
            style={{ order: 3 }}
            className={styles.noMargin}
            value={PERMISSION_STATUS_CUSTOM}
            control={
              <Radio
                disabled={isReadOnlyUser}
                className={clsx(!desktop && formStyles.radioButton, desktop && styles.radioButton)}
              />
            }
            label={
              <Typography variant="bodyBold">
                {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.USER_CUSTOMIZED')}
              </Typography>
            }
          />
          <div style={{ order: 4 }}>
            <Select
              disabled={isReadOnlyUser}
              selectedOption={{
                title: t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ADOPT_PERMISSIONS_FROM'),
                id: 'placeholderLabelOnly',
              }}
              options={users.map(({ customerNumber, settings }) => ({
                id: customerNumber,
                title: settings.name,
              }))}
              onOptionSelected={(option) => copyUserPermissions(option.id.toString())}
              rootClassName={styles.copyUserPermissions}
              menuClasses={{ menuList: clsx(styles.menuList) }}
              paperClassname={clsx(styles.dropdown, styles.dropdownPaper)}
            />
          </div>
        </RadioGroup>
      </Box>

      <div
        className={clsx(styles.groupContainer, compact && styles.compact, mobile && styles.mobile)}
      >
        <CollapseComponent
          key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ADMINISTRATION')}
          header={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ADMINISTRATION')}
          withCheckbox={true}
          ClassificationIcon={AdminPanelSettingsIcon}
          onCheckboxChange={(value) => handleChangeParentCheckbox(PERMISSION_KEYS_GROUP_ONE, value)}
          parentCheckboxValue={PERMISSION_KEYS_GROUP_ONE.every((permission) =>
            Boolean(user.permissions[permission.translationKey])
          )}
          indeterminate={initialIndeterminateSet(PERMISSION_KEYS_GROUP_ONE)}
          disabledCheckbox={isReadOnlyUser}
        >
          <CollapsibleCheckboxGroup
            permissionsList={[
              PERMISSION_KEYS_GROUP_ONE.slice(0, 2),
              PERMISSION_KEYS_GROUP_ONE.slice(2, 3),
            ]}
            updateUser={updateUser}
            user={user}
            onChange={() => setForcedCustomStatus(false)}
            forceMinWidth={forceMinWidth}
            disabled={isReadOnlyUser}
          />
        </CollapseComponent>
      </div>

      <div className={clsx(styles.groupContainer, compact && styles.compact)}>
        <CollapseComponent
          key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PRICES_AND_ACCOUNTING')}
          header={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.PRICES_AND_ACCOUNTING')}
          withCheckbox={true}
          ClassificationIcon={PaymentsIcon}
          onCheckboxChange={(value) => handleChangeParentCheckbox(PERMISSION_KEYS_GROUP_TWO, value)}
          parentCheckboxValue={PERMISSION_KEYS_GROUP_TWO.every((permission) =>
            Boolean(user.permissions[permission.translationKey])
          )}
          indeterminate={initialIndeterminateSet(PERMISSION_KEYS_GROUP_TWO)}
          disabledCheckbox={isReadOnlyUser}
        >
          <CollapsibleCheckboxGroup
            permissionsList={[
              [PERMISSION_KEYS_GROUP_TWO[0], PERMISSION_KEYS_GROUP_TWO[1]],
              [PERMISSION_KEYS_GROUP_TWO[2]],
            ]}
            updateUser={updateUser}
            user={user}
            onChange={() => setForcedCustomStatus(false)}
            forceMinWidth={forceMinWidth}
            disabled={isReadOnlyUser}
          />
        </CollapseComponent>
      </div>

      <div className={clsx(styles.groupContainer, compact && styles.compact)}>
        <CollapseComponent
          key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.OTHER_FEATURES')}
          header={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.OTHER_FEATURES')}
          withCheckbox={true}
          ClassificationIcon={RuleFolderIcon}
          onCheckboxChange={(value) =>
            handleChangeParentCheckbox(PERMISSION_KEYS_GROUP_THREE, value)
          }
          parentCheckboxValue={PERMISSION_KEYS_GROUP_THREE.every((permission) =>
            Boolean(user.permissions[permission.translationKey])
          )}
          indeterminate={initialIndeterminateSet(PERMISSION_KEYS_GROUP_THREE)}
          disabledCheckbox={isReadOnlyUser}
        >
          <CollapsibleCheckboxGroup
            permissionsList={[
              [
                PERMISSION_KEYS_GROUP_THREE[0],
                PERMISSION_KEYS_GROUP_THREE[1],
                PERMISSION_KEYS_GROUP_THREE[3],
              ],
              [
                PERMISSION_KEYS_GROUP_THREE[2],
                PERMISSION_KEYS_GROUP_THREE[4],
                PERMISSION_KEYS_GROUP_THREE[5],
              ],
            ]}
            updateUser={updateUser}
            user={user}
            onChange={() => setForcedCustomStatus(false)}
            forceMinWidth={forceMinWidth}
            disabled={isReadOnlyUser}
          />
        </CollapseComponent>
      </div>

      <div className={clsx(styles.groupContainer, compact && styles.compact)}>
        <CollapseComponent
          key={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_DETAILS')}
          header={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_DETAILS')}
          withCheckbox
          ClassificationIcon={LocalShippingIcon}
          onCheckboxChange={(value) =>
            handleChangeParentCheckbox(PERMISSION_KEYS_GROUP_FOUR, value)
          }
          parentCheckboxValue={PERMISSION_KEYS_GROUP_FOUR.every((permission) =>
            Boolean(user.permissions[permission.translationKey])
          )}
          indeterminate={initialIndeterminateSet(PERMISSION_KEYS_GROUP_FOUR)}
          disabledCheckbox={isReadOnlyUser}
        >
          <CollapsibleCheckboxGroup
            permissionsList={[
              [
                PERMISSION_KEYS_GROUP_FOUR[0],
                PERMISSION_KEYS_GROUP_FOUR[2],
                PERMISSION_KEYS_GROUP_FOUR[4],
              ],
              [PERMISSION_KEYS_GROUP_FOUR[1], PERMISSION_KEYS_GROUP_FOUR[3]],
            ]}
            updateUser={updateUser}
            user={user}
            onChange={() => setForcedCustomStatus(false)}
            forceMinWidth={forceMinWidth}
            disabled={isReadOnlyUser}
            relations={[
              {
                dependencies: [PERMISSION_KEYS_GROUP_FOUR[1]],
                target: PERMISSION_KEYS_GROUP_FOUR[3],
              },
            ]}
          />
        </CollapseComponent>
      </div>

      <Box className={styles.basicStyling}></Box>
    </div>
  )
}
