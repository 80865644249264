import { useState, SetStateAction, Dispatch } from 'react'
import {
  CART_ORDERABLE_COMPLETE_RANGE,
  CART_PERMISSION_STATUS_OWN_CART,
  CartOrderableStatus,
  CartPermissionStatus,
} from '@obeta/utils/lib/user-management/cart-status'

type UserPermissionStates = {
  name: string | null
  email: string | null
  projectCartIds: string[]
  cartPermissionStatus: CartPermissionStatus
  allowedToOrder: boolean
  cartOrderableStatus: CartOrderableStatus | undefined
  maxValueFullRange: number | null | undefined
  maxValueFullRangeActive: boolean
  maxValueOrders: number | null | undefined
  maxValueOrdersActive: boolean
  usersToNotify: string[]
}

type UsePermissionStateSetters = {
  setName: Dispatch<SetStateAction<string | null>>
  setEmail: Dispatch<SetStateAction<string | null>>
  setProjectCartIds: Dispatch<SetStateAction<string[]>>
  setCartPermissionStatus: Dispatch<SetStateAction<CartPermissionStatus>>
  setAllowedToOrder: Dispatch<SetStateAction<boolean>>
  setCartOrderableStatus: Dispatch<SetStateAction<CartOrderableStatus | undefined>>
  setMaxValueFullRange: Dispatch<SetStateAction<number | null | undefined>>
  setMaxValueFullRangeActive: Dispatch<SetStateAction<boolean>>
  setMaxValueOrders: Dispatch<SetStateAction<number | null | undefined>>
  setMaxValueOrdersActive: Dispatch<SetStateAction<boolean>>
  setUsersToNotify: Dispatch<SetStateAction<string[]>>
}

const defaultUserPermissionStates: UserPermissionStates = {
  name: null,
  email: null,
  cartOrderableStatus: CART_ORDERABLE_COMPLETE_RANGE,
  cartPermissionStatus: CART_PERMISSION_STATUS_OWN_CART,
  allowedToOrder: false,
  maxValueOrdersActive: false,
  maxValueFullRangeActive: false,
  maxValueOrders: undefined,
  maxValueFullRange: undefined,
  usersToNotify: [],
  projectCartIds: [],
}

export const usePermissionStates = (
  initial?: UserPermissionStates
): UserPermissionStates & UsePermissionStateSetters => {
  const initialState = { ...defaultUserPermissionStates, ...initial }

  // step 1
  const [name, setName] = useState<string | null>(initialState.name)
  const [email, setEmail] = useState<string | null>(initialState.email)

  // step 3 - cart
  const [projectCartIds, setProjectCartIds] = useState<Array<string>>(initialState.projectCartIds)
  const [cartPermissionStatus, setCartPermissionStatus] = useState<CartPermissionStatus>(
    initialState.cartPermissionStatus
  )

  // step 3 - cart orderable
  const [allowedToOrder, setAllowedToOrder] = useState(initialState.allowedToOrder)
  const [cartOrderableStatus, setCartOrderableStatus] = useState<CartOrderableStatus | undefined>(
    initialState.cartOrderableStatus
  )
  const [maxValueFullRange, setMaxValueFullRange] = useState<number | null | undefined>(
    initialState.maxValueFullRange
  )
  const [maxValueFullRangeActive, setMaxValueFullRangeActive] = useState<boolean>(
    initialState.maxValueFullRangeActive
  )
  const [maxValueOrders, setMaxValueOrders] = useState<number | null | undefined>(
    initialState.maxValueOrders
  )
  const [maxValueOrdersActive, setMaxValueOrdersActive] = useState<boolean>(
    initialState.maxValueOrdersActive
  )

  // step 3 - notify cart
  const [usersToNotify, setUsersToNotify] = useState<Array<string>>(initialState.usersToNotify)

  return {
    name,
    setName,
    email,
    setEmail,
    projectCartIds,
    setProjectCartIds,
    cartPermissionStatus,
    setCartPermissionStatus,
    allowedToOrder,
    setAllowedToOrder,
    cartOrderableStatus,
    setCartOrderableStatus,
    maxValueFullRange,
    setMaxValueFullRange,
    maxValueFullRangeActive,
    setMaxValueFullRangeActive,
    maxValueOrders,
    setMaxValueOrders,
    maxValueOrdersActive,
    setMaxValueOrdersActive,
    usersToNotify,
    setUsersToNotify,
  }
}
