import { FC, useCallback, useRef } from 'react'
import { CellMeasurerCache, List } from 'react-virtualized'

// Components
import { InfiniteWindowScroller, TInfiniteLoaderProps } from '../window-scroller'
import { OrderListItemV2 } from './OrderListItemV2'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import { useOrderListContext } from '@obeta/data/lib/stores/useOrderListContext'

// Models
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'

// Utils
import { updateNameInListPage } from '@obeta/utils/lib/orders-helpers'
import { useScopedScrollRestorationContext } from '@obeta/data/lib/hooks'

// Constants
const INITIAL_ORDER_COUNT = 20

type OrderListVirtualizedProps = {
  onLoadMoreOrders: (queryParams: QueryParams) => void
}

export const OrderListVirtualized: FC<OrderListVirtualizedProps> = (props) => {
  const { onLoadMoreOrders } = props

  const { mobile, tabletAll } = useBreakpoints()
  const { orders, queryParams, totalOrderCount, setOrders, setQueryParams } = useOrderListContext()
  const { cellMeasureCache, lastScrollY } = useScopedScrollRestorationContext({
    scopeName: 'ordersDetailPage',
    defaults: {
      cellMeasureCache: new CellMeasurerCache({ fixedWidth: true }),
    },
  })
  const listReference = useRef<List>(null)

  const rowCount = orders.length
  const rowTotalCount = totalOrderCount ?? 0
  /**
   * Tracking the loaded state of each row.
   * @param TInfiniteLoaderProps
   */
  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => {
      return Boolean(orders[index])
    },
    [orders]
  )

  /**
   * Load more rows (orders) when InfiniteLoader threshold is reached.
   */
  const onLoadMoreRows = useCallback(
    async () => {
      const updatedQueryParams = { ...queryParams, offset: orders.length.toString() }
      setQueryParams(updatedQueryParams)
      onLoadMoreOrders(updatedQueryParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orders, queryParams]
  )

  /**
   * Update order name by given input.
   * @param id Order id
   * @param name Order name
   */
  const onEditName = useCallback(
    (id: string, name: string) => {
      const updatedOrders = updateNameInListPage(id, name, orders)
      setOrders(updatedOrders)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orders]
  )

  /**
   * recomputeRowHeights if an item shipment info has been toggled.
   */
  const onToggleShowShipment = useCallback(
    (index) => {
      listReference.current?.recomputeRowHeights(index)
    },
    [listReference]
  )

  const { rowRenderer } = useMeasuredRowRenderer({
    cache: cellMeasureCache,
    render: ({ index }) => {
      const order = orders[index]

      if (!order) {
        return null
      }

      return (
        <div style={{ paddingBottom: mobile ? '0.5rem' : '1rem' }}>
          <OrderListItemV2
            order={order}
            onToggleShowShipping={onToggleShowShipment}
            onEditName={onEditName}
            index={index}
          />
        </div>
      )
    },
  })

  return (
    <InfiniteWindowScroller
      infiniteLoader={{
        isRowLoaded,
        loadMoreRows: onLoadMoreRows,
        minimumBatchSize: INITIAL_ORDER_COUNT, // Amount of order count to load at a time
        threshold: mobile || tabletAll ? 5 : 2, // Load more items when within the last 5/2 rows
      }}
      list={{
        rowRenderer,
        rowHeight: cellMeasureCache?.rowHeight || 0,
        deferredMeasurementCache: cellMeasureCache,
      }}
      listReference={listReference}
      preventScrollRestoration={true}
      rowCount={rowCount} // Row count for loaded orders
      rowTotalCount={rowTotalCount} // Total row count for orders (including not loaded yet orders)
      onInitialRender={() => {
        window.scrollTo({ top: lastScrollY })
      }}
    />
  )
}
