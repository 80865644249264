import React, { useRef, useState } from 'react'
import styles from './SignUpStepThree.module.scss'
import clsx from 'clsx'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { sanitize } from '@obeta/utils/lib/sanitize'
import SignUpStepThreeForm from './SignUpStepThreeForm'
import { Typography } from '@mui/material'
import { useCompanyDetection } from '@obeta/data/lib/hooks/useCompanyDetection'
import { UserRegistrationPendingModal } from './UserRegistrationPendingModal'
import { UserRegistrationSuccessModal } from './UserRegistrationSuccessModal'
import { useSignUpStepThree, useUserRegistration } from '@obeta/data/lib/hooks/useUserRegistration'
import { useTranslation } from 'react-i18next'

const SignUpStepThree = ({ control, customerCardCount, getValues, setValue }) => {
  const { submitUserRegistration } = useUserRegistration()
  const { t } = useTranslation()
  const [showPendingModal, setShowPendingModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const domain = useCompanyDetection()
  const captchaRef = useRef<HCaptcha>(null)
  const {
    setCaptchaToken,
    termsAccepted,
    privacyPolicyAccepted,
    uploadedFiles,
    setUploadedFiles,
    isLoading,
    setIsLoading,
  } = useSignUpStepThree()

  const handleRegistrationResponse = (response) => {
    const { submitUserRegistration } = response.data || {}

    setShowPendingModal(false)

    if (submitUserRegistration?.success) {
      handleSuccessfulRegistration()
    } else {
      handleFailedRegistration(submitUserRegistration?.files)
    }
  }

  const handleSuccessfulRegistration = () => {
    setShowSuccessModal(true)
  }

  const handleFailedRegistration = (s3KeysOfFailedFiles: string[]) => {
    removeFailedFileUploads(s3KeysOfFailedFiles)
    displayErrorNotification()
  }

  const removeFailedFileUploads = (s3KeysOfFailedFiles: string[]) => {
    if (s3KeysOfFailedFiles) {
      setUploadedFiles(uploadedFiles.filter((file) => !s3KeysOfFailedFiles.includes(file.s3Key)))
    }
  }

  const displayErrorNotification = () => {
    getEventSubscription().next({
      type: EventType.Alert,
      notificationType: NotificationType.Error,
      id: 'user-registration-failed',
      options: {
        title: t('SIGNUP.NOTIFICATIONS.ATTENTION'),
        message: t('SIGNUP.NOTIFICATIONS.ISSUE_WITH_SENDING_DATA'),
      },
    })
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    setShowPendingModal(true)
    try {
      const values = getValues()
      const input = {
        ...values,
        additionalText: sanitize(values.additionalText),
        attachments: uploadedFiles.map((file) => file.s3Key),
        city: sanitize(values.city),
        companyDescription: sanitize(values.companyDescription),
        companyId: domain,
        companyName: sanitize(values.companyName),
        customerCardCount: customerCardCount,
        electrician: sanitize(values.electrician),
        email: sanitize(values.email),
        firstName: sanitize(values.firstName),
        houseNumber: sanitize(values.houseNumber),
        invoiceEmail: sanitize(values.invoiceEmail),
        lastName: sanitize(values.lastName),
        phone: sanitize(values.phone),
        street: sanitize(values.street),
        taxNumber: sanitize(values.taxNumber),
        zipCode: sanitize(values.zipCode),
      }
      const response = await submitUserRegistration(input)
      handleRegistrationResponse(response)
    } catch (error) {
      // Handle error
      console.error('error: ', error)
      setShowPendingModal(false)
    } finally {
      setIsLoading(false)
      setShowPendingModal(false)
      captchaRef.current?.resetCaptcha()
    }
  }

  return (
    <>
      <form className={clsx(styles.container)} onSubmit={handleFormSubmit}>
        <SignUpStepThreeForm control={control} />

        <div className={styles.marginBottom}>
          <HCaptcha
            sitekey={String(process.env.HCAPTCHA_SITE_KEY)}
            onVerify={setCaptchaToken}
            onExpire={() => setCaptchaToken('')}
            onError={() => setCaptchaToken('')}
            ref={captchaRef}
          />
        </div>
        <div className={styles.requiredText}>
          <Typography variant="smallText">{`* ${t('SIGNUP.REQUIRED_FIELD')}`}</Typography>
        </div>

        <div className={styles.buttonWrapper}>
          <TertiaryButton leftIcon={<ChevronLeftIcon />} onClick={() => setValue('2')}>
            {t('SIGNUP.BACK')}
          </TertiaryButton>

          <DarkButton
            type="submit"
            rightIcon={<CheckIcon />}
            disabled={
              isLoading || !termsAccepted || !privacyPolicyAccepted || uploadedFiles.length === 0
              // || captchaToken === ''
            }
            fullWidth
          >
            {t('SIGNUP.SEND')}
          </DarkButton>
        </div>
      </form>
      {showPendingModal && <UserRegistrationPendingModal />}
      {showSuccessModal && <UserRegistrationSuccessModal />}
    </>
  )
}

export default SignUpStepThree
