import Grid from '@mui/material/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import de from 'dayjs/locale/de'
import { TimeFormField } from './TimeFormField'

const localeMap = {
  de: de,
}

export const OpeningHoursSection = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeMap.de.name}>
      <Grid container columnSpacing={2} rowSpacing={1}>
        {Array.from({ length: 7 }).map((_, dayIndex) => (
          <Grid key={dayIndex} size={{ xs: 12, sm: 6 }}>
            <TimeFormField dayIndex={dayIndex} />
          </Grid>
        ))}
      </Grid>
    </LocalizationProvider>
  )
}
