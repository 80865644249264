import { FC } from 'react'
import checkoutSuccessStyles from '../shoppingcart/CheckoutOrderSuccess.module.scss'
import orderPendingStyles from '../shoppingcart/CheckoutOrderPending.module.scss'
import styles from './UserRegistrationModal.module.scss'
import { DarkButton } from '../custom-button/CustomButton'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { Modal, Typography } from '@mui/material'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useTranslation } from 'react-i18next'

export const UserRegistrationSuccessModal: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Modal
      open={true}
      aria-modal="true"
      aria-labelledby="modal-heading"
      aria-describedby="modal-description"
      role="dialog"
    >
      <div className={styles.root}>
        <div className={orderPendingStyles.animationMainContainer}>
          <div className={checkoutSuccessStyles.circleLoader}>
            <div className={checkoutSuccessStyles.checkMark} />
          </div>
        </div>
        <div className={styles.textBlock}>
          <div className={styles.headlines}>
            <Typography id="modal-heading" variant={'headline2Bold'}>
              {t('SIGNUP.SUCCESS_MODAL.CONGRATS')}
            </Typography>
            <Typography id="modal-description" variant={'headline2Bold'}>
              {t('SIGNUP.SUCCESS_MODAL.YOUR_REGISTRATION_WAS_SUCCESSFUL')}
            </Typography>
          </div>
          <div className={styles.body}>
            <Typography variant={'body'}>
              {t('SIGNUP.SUCCESS_MODAL.MYRIAD_FUNCTIONS_AND_SERVICES')}
            </Typography>
            <Typography variant={'body'}>{t('SIGNUP.SUCCESS_MODAL.ENJOY_YOUR_TIME')}</Typography>
            <Typography variant={'body'}>
              {t('SIGNUP.SUCCESS_MODAL.THANKS_FOR_YOUR_TRUST')}
            </Typography>
          </div>
        </div>
        <DarkButton type="submit" fullWidth={false} onClick={() => history.push(ShopRoutes.Root)}>
          {t('SIGNUP.SUCCESS_MODAL.EXPLORE_OBETA')}
        </DarkButton>
      </div>
    </Modal>
  )
}
