import { FC, useCallback, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import styles from './signUpPage.module.scss'
import { ProgressBar } from '@obeta/components/lib/progress-bar/ProgressBar'
import { DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as ArrowRightIcon } from '@obeta/assets/icon/designsystem/keyboard-arrow-right.svg'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { DropdownOption } from '@obeta/models/lib/models/UI'
import SignUpStepOne from '@obeta/components/lib/sign-up/SignUpStepOne'
import SignUpStepTwo from '@obeta/components/lib/sign-up/DesignStepTwo/SignUpStepTwo'
import SignUpStepThree from '@obeta/components/lib/sign-up/SignUpStepThree'

export const SignUpPage: FC = () => {
  const { t } = useTranslation()
  const { mobile, tablet } = useBreakpoints()
  const [value, setValue] = useState('1')
  const [amount, setAmount] = useState<number | null>(0)

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      customerCardCount: 0,
      taxNumber: '',
      invoiceEmail: '',
      companyType: 'electrical',
      companyDescription: '',
      electrician: '',
      additionalText: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [, setVisitedTabs] = useState<Set<string>>(new Set())

  const handleTabChange = (newValue: string) => {
    setVisitedTabs((prev) => new Set(prev).add(newValue))
    setValue(newValue)
  }

  const getTabClass = (tabValue: string) => {
    if (tabValue === value) {
      return styles.activeTab
    } else if (tabValue === '1' && !isAnyFieldEmptyInTab1()) {
      return styles.previousTab
    } else if (tabValue === '2' && !isAnyFieldEmptyInTab2()) {
      return styles.previousTab
    } else if (tabValue === '3') {
      return styles.lastTab
    } else {
      return styles.inactiveTab
    }
  }

  const active = (tabValue: string) => {
    return tabValue === value ? styles.activeLine : styles.inactiveLine
  }

  const onSubmit = () => {
    if (value === '1') {
      setValue('2')
    } else if (value === '2') {
      setValue('3')
    }
  }

  const handleAmountChange = (newAmount: number) => {
    setAmount(newAmount)
  }

  const Options: DropdownOption[] = [
    { value: 'electrical', label: t('COMMON.SIGNUP.TYPE.ELECTRICAL') },
    { value: 'producing', label: t('COMMON.SIGNUP.TYPE.PRODUCING') },
    { value: 'commerce', label: t('COMMON.SIGNUP.TYPE.COMMERCE') },
    { value: 'research', label: t('COMMON.SIGNUP.TYPE.RESEARCH') },
    { value: 'other', label: t('COMMON.SIGNUP.TYPE.AOB') },
  ]
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const isAnyFieldEmptyInTab1 = useCallback(() => {
    const values = getValues()
    return !values.firstName || !values.lastName || !values.phone || !values.email
  }, [getValues])

  const isAnyFieldEmptyInTab2 = useCallback(() => {
    const values = getValues()
    return (
      !values.companyName ||
      !values.street ||
      !values.houseNumber ||
      !values.zipCode ||
      !values.city ||
      !values.taxNumber ||
      !values.invoiceEmail ||
      !values.companyType
    )
  }, [getValues])

  useEffect(() => {
    if (value === '1') {
      setIsButtonDisabled(isAnyFieldEmptyInTab1() || !isValid)
    } else if (value === '2') {
      setIsButtonDisabled(isAnyFieldEmptyInTab2() || !isValid)
    }
  }, [isValid, value, isAnyFieldEmptyInTab1, isAnyFieldEmptyInTab2])

  return (
    <TabContext value={value}>
      <div
        className={clsx([
          !mobile && !tablet && styles.center,
          tablet && styles.centerTabletWide,
          mobile && styles.centerMobile,
        ])}
      >
        <TabPanel className={styles.noPadding} value="1">
          <Typography variant="headline3Bold">{t('COMMON.SIGNUP.STEP1')}</Typography>
        </TabPanel>
        <TabPanel className={styles.noPadding} value="2">
          <Typography variant="headline3Bold">{t('COMMON.SIGNUP.STEP2')}</Typography>
        </TabPanel>
        <TabPanel className={styles.noPadding} value="3">
          <Typography variant="headline3Bold">{t('COMMON.SIGNUP.STEP3')}</Typography>
        </TabPanel>
      </div>
      <div
        className={clsx([
          !mobile && !tablet && styles.barContainer,
          tablet && styles.barContainerTabletWide,
          mobile && styles.barContainerMobile,
          mobile && value === '1' && styles.activeFirst,
          mobile && value === '2' && styles.activeSecond,
          mobile && value === '3' && styles.activeThird,
        ])}
      >
        <ProgressBar
          title={t('COMMON.SIGNUP.PERSONAL_DATA')}
          onChange={() => handleTabChange('1')}
          className={getTabClass('1')}
          active={active('1')}
          isActive={value === '1'}
        />
        <ProgressBar
          title={t('COMMON.SIGNUP.COMPANY')}
          onChange={() => handleTabChange('2')}
          className={getTabClass('2')}
          active={active('2')}
          isActive={value === '2'}
        />
        <ProgressBar
          title={t('COMMON.SIGNUP.SENDING')}
          onChange={() => handleTabChange('3')}
          className={getTabClass('3')}
          active={active('3')}
          isActive={value === '3'}
        />
      </div>

      <div
        className={clsx([
          !mobile && !tablet && styles.center,
          tablet && styles.centerTabletWide,
          mobile && styles.centerMobile,
        ])}
      >
        <TabPanel className={styles.noPadding} value="1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <SignUpStepOne control={control} errors={errors} />
            <div className={styles.right}>
              <Typography className={styles.size}>{t('COMMON.SIGNUP.REQUIRED_FIELD')}</Typography>
              <div className={styles.button}>
                <DarkButton
                  type="submit"
                  rightIcon={<ArrowRightIcon />}
                  disabled={isButtonDisabled}
                  fullWidth
                >
                  {t('COMMON.SIGNUP.NEXT')}
                </DarkButton>
              </div>
            </div>
          </form>
        </TabPanel>

        <TabPanel className={styles.noPadding} value="2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={clsx([
                !mobile && !tablet && styles.center,
                tablet && styles.centerTabletWide,
                mobile && styles.centerMobile,
              ])}
            >
              <SignUpStepTwo
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                setValue={setValue}
                getValues={getValues}
                amount={amount}
                Options={Options}
                handleAmountChange={handleAmountChange}
                value={value}
              />
            </div>
          </form>
        </TabPanel>
        <TabPanel className={clsx(styles.noPadding, styles.fullWidth)} value="3">
          <SignUpStepThree
            control={control}
            customerCardCount={amount}
            getValues={getValues}
            setValue={setValue}
          />
        </TabPanel>
      </div>
    </TabContext>
  )
}

export default SignUpPage
