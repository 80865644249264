import { FetchResult, gql, useMutation } from '@apollo/client'
import {
  SubmitUserRegistrationInput,
  SubmitUserRegistrationMutation,
  CreateUserRegistrationUploadUrlInput,
  CreateUserRegistrationUploadUrlMutation,
  DeleteUserRegistrationFileUploadInput,
  DeleteUserRegistrationFileUploadMutation,
} from '@obeta/schema'

import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { uploadFile } from '@obeta/utils/lib/upload-utils/uploadFile'

interface UploadedUserFile {
  name: string
  s3Key: string
  size: number
}
export const CREATE_UPLOAD_URL = gql`
  mutation createUserRegistrationUploadUrl($input: CreateUserRegistrationUploadUrlInput!) {
    createUserRegistrationUploadUrl(input: $input) {
      params
      s3Key
      uploadUrl
    }
  }
`
export const DELETE_FILE_UPLOAD = gql`
  mutation deleteUserRegistrationFileUpload($input: DeleteUserRegistrationFileUploadInput!) {
    deleteUserRegistrationFileUpload(input: $input) {
      success
      errorCode
      errorMessage
    }
  }
`

export const SUBMIT_USER_REGISTRATION = gql`
  mutation submitUserRegistration($input: SubmitUserRegistrationInput!) {
    submitUserRegistration(input: $input) {
      errorCode
      errorMessage
      files
      success
    }
  }
`

export const useUserRegistration = () => {
  const [submitUserRegistrationMutation] = useMutation<SubmitUserRegistrationMutation>(
    SUBMIT_USER_REGISTRATION,
    {
      onCompleted: (data) => {
        return data.submitUserRegistration
      },
      onError: (error) => {
        return error
      },
    }
  )

  const [createUploadUrlMutation] = useMutation<CreateUserRegistrationUploadUrlMutation>(
    CREATE_UPLOAD_URL,
    {
      onCompleted: (data) => {
        return data.createUserRegistrationUploadUrl
      },
      onError: (error) => {
        return error
      },
    }
  )

  const [deleteFileUploadMutation] = useMutation<DeleteUserRegistrationFileUploadMutation>(
    DELETE_FILE_UPLOAD,
    {
      onCompleted: (data) => {
        return data.deleteUserRegistrationFileUpload
      },
      onError: (error) => {
        return error
      },
    }
  )

  const submitUserRegistration = async (
    input: SubmitUserRegistrationInput
  ): Promise<FetchResult<SubmitUserRegistrationMutation>> => {
    return await submitUserRegistrationMutation({ variables: { input } })
  }

  const createUploadUrl = async (
    input: CreateUserRegistrationUploadUrlInput
  ): Promise<FetchResult<CreateUserRegistrationUploadUrlMutation>> => {
    return await createUploadUrlMutation({ variables: { input } })
  }

  const deleteFileUpload = async (
    input: DeleteUserRegistrationFileUploadInput
  ): Promise<FetchResult<DeleteUserRegistrationFileUploadMutation>> => {
    return await deleteFileUploadMutation({ variables: { input } })
  }

  return {
    deleteFileUpload,
    submitUserRegistration,
    createUploadUrl,
  }
}

export const useSignUpStepThree = () => {
  const { t } = useTranslation()

  const [captchaToken, setCaptchaToken] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [fileError, setFileError] = useState<string | undefined>(undefined)
  const [uploadedFiles, setUploadedFiles] = useState<UploadedUserFile[]>([])
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { createUploadUrl, deleteFileUpload } = useUserRegistration()

  const MAX_FILE_COUNT = 5
  const MAX_FILE_SIZE_IN_BYTES = 38797312 // = 37 MB
  const MAX_FILE_SIZE_IN_MEGA_BYTES = MAX_FILE_SIZE_IN_BYTES / (1024 * 1024)

  const handleDeleteFile = async (s3Key: string) => {
    await deleteFileUpload({ s3Key: s3Key })
    setUploadedFiles((files) => files.filter((file) => file.s3Key !== s3Key))
    setFileError(undefined)
  }
  const handleFileUploadError = useCallback(() => {
    getEventSubscription().next({
      options: { id: 'user-registration-file-upload-error' },
      type: EventType.Toast,
      notificationType: NotificationType.FastProductEntryFileUploadErrorCommon,
      id: 'user-registration-file-upload-error',
    })
    setIsFileUploading(false)
  }, [])

  const handleDropRejected = (files) => {
    files.forEach((file) => {
      file.errors &&
        file.errors.forEach((error) => {
          // Notes that these errors only apply per drop and/or per file respectively
          // Hence, we set the file error to the first error encountered and then return early
          switch (error.code) {
            case 'too-many-files':
              setFileError(
                t('SIGNUP.WARNING.MAX_FILE_COUNT_EXCEEDED', {
                  maxFiles: MAX_FILE_COUNT,
                })
              )
              return
            case 'file-invalid-type':
              setFileError(t('SIGNUP.WARNING.INVALID_FILE_TYPE'))
              return
            case 'file-too-large':
              setFileError(
                t('SIGNUP.WARNING.FILE_SIZE_EXCEEDED', {
                  maxFileSizeInMB: MAX_FILE_SIZE_IN_MEGA_BYTES,
                })
              )
              return
          }
        })
    })
    return
  }

  const isTotalFileSizeValid = (acceptedFiles: File[]) => {
    const uploadedFilesSize = uploadedFiles.reduce((total, file) => {
      return total + (file.size || 0)
    }, 0)

    const acceptedFilesSize = acceptedFiles.reduce((total, file) => total + file.size, 0)

    // Check if total size exceeds limit
    const totalSize = uploadedFilesSize + acceptedFilesSize
    if (totalSize > MAX_FILE_SIZE_IN_BYTES) {
      setFileError(
        t('SIGNUP.WARNING.FILE_SIZE_EXCEEDED', {
          maxFileSizeInMB: MAX_FILE_SIZE_IN_MEGA_BYTES,
        })
      )
      return false
    }

    return true
  }

  const isLatestFileDropValid = (acceptedFiles: File[]) => {
    // Note: File type has been evaluated already by useDropzone and would have caused files to be rejected

    // First, check if total file count exceeds limit
    if (uploadedFiles.length + acceptedFiles.length > MAX_FILE_COUNT) {
      setFileError(
        t('SIGNUP.WARNING.MAX_FILE_COUNT_EXCEEDED', {
          maxFiles: MAX_FILE_COUNT,
        })
      )
      return false
    }
    return isTotalFileSizeValid(acceptedFiles)
  }

  const uploadFileToAWS = async (file: File) => {
    try {
      const { data } = await createUploadUrl({ contentType: file.type, fileName: file.name })
      if (!data) {
        console.error('Failed to create upload URL')
        setFileError(t('SIGNUP.WARNING.GENERIC'))
        return
      }

      const response = await uploadFile(file, data.createUserRegistrationUploadUrl)
      if (response?.status !== 201) {
        console.error('File upload failed')
        setFileError(t('SIGNUP.WARNING.GENERIC'))
        return
      }

      setUploadedFiles((files) => [
        ...files,
        {
          name: file.name,
          s3Key: data.createUserRegistrationUploadUrl.s3Key,
          size: file.size,
        },
      ])
    } catch (error) {
      setFileError(t('SIGNUP.WARNING.GENERIC'))
      console.error(error)
    }
  }
  const handleDropAccepted = async (acceptedFiles: File[]) => {
    setFileError(undefined)
    if (acceptedFiles.length === 0) return

    setIsFileUploading(true)

    // Add checks before attempting to upload the files to AWS
    if (!isLatestFileDropValid(acceptedFiles)) {
      setIsFileUploading(false)
      return
    }
    try {
      await Promise.all(acceptedFiles.map((file) => uploadFileToAWS(file)))
    } catch (error) {
      handleFileUploadError()
    } finally {
      setIsFileUploading(false)
    }
  }
  return {
    captchaToken,
    setCaptchaToken,
    termsAccepted,
    setTermsAccepted,
    privacyPolicyAccepted,
    setPrivacyPolicyAccepted,
    fileError,
    uploadedFiles,
    setUploadedFiles,
    isFileUploading,
    isLoading,
    setIsLoading,
    handleDropAccepted,
    handleDropRejected,
    handleDeleteFile,
    MAX_FILE_COUNT,
    MAX_FILE_SIZE_IN_BYTES,
  }
}
