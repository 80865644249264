import React, { useState, useRef } from 'react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import './App.scss'
import { ArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useBarcodeState, BarcodeContext } from '@obeta/data/lib/hooks/useBarcodeState'
import { CategoryContext, useCategoriesState } from '@obeta/data/lib/hooks/useCategoriesList'
import { Onboarding } from './pages'
import { Sockets } from '@obeta/components/lib/sockets/Sockets'
import { RouterWrapper } from '@obeta/components/lib/router/Router'
import { loadI18n } from '@obeta/utils/lib/i18n'
import 'assets/theme/variables.scss'
import 'assets/theme/overrides.scss'
import { usePushNotifications } from '@obeta/data/lib/hooks/usePushNotifications'

import { CartSubmittedPopover } from './popovers/cart-submitted/CartSubmittedPopover'
import { PermissionForPushNotifications } from './popovers/permission-for-push-notifications/PermissionForPushNotification'
import {
  GlobalNotifications,
  IGlobalNotificationsApi,
} from '@obeta/components/lib/global-notifications/GlobalNotifications'
import { UserV2DataContext, useInitialUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { AccessProtector } from './common/wrappers/AccessProtector'
import { Scannerbutton } from '@obeta/components/lib/scanner-button/Scannerbutton'
import { LoginHeader } from './common/components/LoginHeader'
import { HeaderDimensionsContextProvider } from '@obeta/data/lib/hooks/useHeaderDimensions'
import { ldProviderConfig } from './common/configs/ldProviderConfig'
import { useFetchInitialData } from './common/hooks/useFetchInitialData'
import { useStatusBarAndSplashScreenSetup } from './common/hooks/useStatusBarAndSplashScreenSetup'
import { useUserAnalytics } from './common/hooks/useUserAnalytics'
import { AppRoutes } from './routes/AppRoutes'
import { MainNavbar } from './common/components/main-navbar/MainNavbar'
import { OnlineStatusWarnings } from './common/components/online-status-warnings/OnlineStatusWarnings'
import { useLogoutCleanup } from '@obeta/data/lib/hooks/useLogoutCleanup'
import { GoggleMapsContextProvider } from '@obeta/data/lib/hooks/googleMaps/GoogleMapsContextProvider'
import { LicenseInfo } from '@mui/x-license-pro'
import { OrderListProvider } from '@obeta/data/lib/stores/useOrderListContext'
import { WarehouseProvider } from '@obeta/data/lib/stores/useWarehouseContext'
import { OfferDetailsContextProvider } from '@obeta/data/lib/stores/useOfferDetailsContext'
import { AppUrlOpenListener } from './common/wrappers/AppUrlOpenListener'
import { FastProductEntryProvider } from '@obeta/data/lib/stores/useFastProductEntryContext'
import { ScopedScrollRestorationProvider } from '@obeta/data/lib/hooks'
import { OrderDetailsContextProvider } from '@obeta/data/lib/stores/useOrderDetailsContext'
import { OrderDetailsInArrearsContextProvider } from '@obeta/data/lib/stores/useOrderDetailsInArrearsContext'
import { CompanyContactDetailsProvider } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'
import { CustomerMain } from './common/components/customer-main/CustomerMain'
import { CustomerMainManagerProvider } from './common/components/customer-main/CustomerMainManagerProvider'
import { CreditLimitPreAlertProvider } from '@obeta/data/lib/stores/useCreditLimitPreAlertContext'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import { useOxomiProductEnhancement } from '@obeta/data/lib/hooks/useOxomiProductEnhancement'
import { CartTemplatesListContextProvider } from '@obeta/data/lib/stores/useCartTemplatesListContext'

loadI18n()

const AppComp: React.FC = () => {
  // TODO: Investigate whether we can find a better way to reset notifications
  const notificationsRef = useRef<IGlobalNotificationsApi>(null)

  const [skipOnboarding, setSkipOnboarding] = useState(
    localStorage.getItem('skipOnboarding') === '1'
  )

  const userV2Data = useInitialUserDataV2()
  const barcodeState = useBarcodeState()
  const { state, dispatchRef } = useCategoriesState()
  usePushNotifications()
  useStatusBarAndSplashScreenSetup()
  useUserAnalytics(userV2Data)

  const { isLoggedIn, companyId, userId } = userV2Data

  useLogoutCleanup(isLoggedIn)

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? '')

  useFetchInitialData({
    companyId,
    userId,
    notificationsRef,
    isLoggedIn,
  })

  const handleSkipOnboarding = () => {
    localStorage.setItem('skipOnboarding', '1')
    setSkipOnboarding(true)
  }

  // oxomi
  useOxomiIntegration()
  useOxomiProductEnhancement()

  return (
    <UserV2DataContext.Provider value={userV2Data}>
      <WarehouseProvider>
        <CompanyContactDetailsProvider>
          <CartTemplatesListContextProvider>
            <FastProductEntryProvider>
              <CategoryContext.Provider value={{ state, dispatchRef }}>
                <GoggleMapsContextProvider>
                  <BarcodeContext.Provider value={barcodeState}>
                    <div className="pageWrapper">
                      <RouterWrapper>
                        <AppUrlOpenListener>
                          <AccessProtector>
                            <Sockets userId={userId} companyId={companyId}>
                              {!isLoggedIn && <LoginHeader />}
                              <CreditLimitPreAlertProvider>
                                <ArticlesSearchProvider>
                                  <OrderListProvider>
                                    <OrderDetailsContextProvider>
                                      <OrderDetailsInArrearsContextProvider>
                                        <OfferDetailsContextProvider>
                                          <OnlineStatusWarnings />
                                          {!isLoggedIn && !skipOnboarding ? (
                                            <Onboarding onSkip={handleSkipOnboarding} />
                                          ) : (
                                            <HeaderDimensionsContextProvider>
                                              {barcodeState.scan && <Scannerbutton />}
                                              <GlobalNotifications ref={notificationsRef} />
                                              <CartSubmittedPopover anchorEl="ion-tab-bar" />
                                              <PermissionForPushNotifications
                                                asModal={true}
                                                openCondition="onCartSubmit"
                                              />
                                              <CustomerMainManagerProvider>
                                                <CustomerMain />
                                                <MainNavbar>
                                                  <ScopedScrollRestorationProvider>
                                                    <AppRoutes />
                                                  </ScopedScrollRestorationProvider>
                                                </MainNavbar>
                                              </CustomerMainManagerProvider>
                                            </HeaderDimensionsContextProvider>
                                          )}
                                        </OfferDetailsContextProvider>
                                      </OrderDetailsInArrearsContextProvider>
                                    </OrderDetailsContextProvider>
                                  </OrderListProvider>
                                </ArticlesSearchProvider>
                              </CreditLimitPreAlertProvider>
                            </Sockets>
                          </AccessProtector>
                        </AppUrlOpenListener>
                      </RouterWrapper>
                    </div>
                  </BarcodeContext.Provider>
                </GoggleMapsContextProvider>
              </CategoryContext.Provider>
            </FastProductEntryProvider>
          </CartTemplatesListContextProvider>
        </CompanyContactDetailsProvider>
      </WarehouseProvider>
    </UserV2DataContext.Provider>
  )
}

export const App = withLDProvider(ldProviderConfig)(AppComp)
