import { FC } from 'react'
import checkoutSuccessStyles from '../shoppingcart/CheckoutOrderSuccess.module.scss'
import orderPendingStyles from '../shoppingcart/CheckoutOrderPending.module.scss'
import overlayStyles from '../shoppingcart/CheckoutEditAddressOverlay.module.scss'
import styles from './UserRegistrationModal.module.scss'
import { Modal, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export const UserRegistrationPendingModal: FC = () => {
  const { t } = useTranslation()

  return (
    <Modal open={true} aria-modal="true" aria-labelledby="modal-heading" role="dialog">
      <div className={clsx(styles.root, overlayStyles.checkoutEditAddressOverlay)} id={styles.root}>
        <div className={orderPendingStyles.animationMainContainer}>
          <div className={checkoutSuccessStyles.circleLoader}></div>
        </div>
        <div className={styles.headlines}>
          <Typography id="modal-heading" variant={'headline3Bold'}>
            {t('SIGNUP.DATA_CHECKING')}
          </Typography>
        </div>
      </div>
    </Modal>
  )
}
